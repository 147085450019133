'use client';

import type React from 'react';
import { useEffect, useMemo } from 'react';

const isDevable = process.env.NODE_ENV !== 'production' && typeof globalThis.localStorage !== 'undefined';

// Read a flag from localStorage, if available, and not in production.
export const useDevFlag = isDevable
  ? <T = string>(name: string, parse?: (_: string) => T): T | null => {
      const value = globalThis.localStorage.getItem(name);
      return parse != null && value != null ? parse(value) : (value as T);
    }
  : () => void 0;

/**
 * `useEffect`, with some delay.
 */
export const useDelayedEffect = (
  delayMs: number | null | undefined,
  fn: React.EffectCallback,
  deps: React.DependencyList = []
): void => {
  delayMs = delayMs ?? 0;
  const givenFn = fn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  fn = useMemo(() => givenFn, deps);

  useEffect(() => {
    if (!delayMs) {
      return fn();
    }

    let cleanup: ReturnType<React.EffectCallback> | undefined;
    let timer: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
      cleanup = fn();
    }, delayMs);
    return () => {
      if (timer != null) {
        clearTimeout(timer);
        timer = void 0;
      }
      if (cleanup) {
        cleanup();
        cleanup = void 0;
      }
    };
  }, [fn, delayMs]);
};

/**
 * `useEffect`, possibly delayed by a flag in localStorage.
 */
export const useEffectWithDevDelay: (flagName: string, fn: React.EffectCallback, deps?: React.DependencyList) => void =
  isDevable
    ? (flagName, fn, deps) => {
        useDelayedEffect(
          useDevFlag(flagName, n => parseInt(n, 10)),
          fn,
          deps
        );
      }
    : // eslint-disable-next-line react-hooks/exhaustive-deps
      (_, fn, deps) => void useEffect(fn, deps);
