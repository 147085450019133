'use client';

import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useRef, useTransition } from 'react';

import { useDevFlag } from '@/flag';
import { Dialog } from '@/lib/primer';

export default function LoginDialog(props: { via?: string } = {}) {
  const [isPending, startTransition] = useTransition();

  const router = useRouter();

  // Don't use "signIn" from 'next-auth/react': it queries for providers, but we don't need that here.
  const safeSignIn = useCallback(() => {
    const p = new URLSearchParams();
    p.set('callbackUrl', window.location.href);
    router.push(`/auth/signin?${p.toString()}`);
  }, [router]);

  // FIXME: should only go back if this is an intercepted /login route.
  // const router = useRouter();
  // const onClose = useCallback(() => (props.via === 'blam' ? void router.back() : void 0), [router, props.via]);
  const onConfirm = useCallback(() => void safeSignIn(), [safeSignIn]);
  const onClose = useCallback(() => void 0, []);

  const pauseLoginRedirect = useDevFlag('debug.pauseLoginRedirect');

  useEffect(
    () => (pauseLoginRedirect || isPending ? undefined : void startTransition(safeSignIn)),
    [safeSignIn, isPending, pauseLoginRedirect]
  );

  const ref = useRef<HTMLButtonElement | null>(null);
  return (
    <Dialog
      title="Login Required"
      initialFocusRef={ref}
      onClose={onClose}
      footerButtons={[
        {
          buttonType: 'primary',
          content: 'Log In',
          autoFocus: true,
          disabled: isPending,
          ref,
          onClick: onConfirm,
        },
      ]}
    >
      {props.via && process.env.NODE_ENV !== 'production' && <>Triggered via {props.via}</>}
    </Dialog>
  );
}
